import { configService } from "./Config";

export class FacebookService {
  async getFbCreds(): Promise<facebook.AuthResponse> {
    return new Promise((resolve, reject) => {

    
    window.FB.getLoginStatus(async (response) => {
      window.FB.login(async (response) => {
        if (response.status === 'connected') {
          console.log('logged in fb');
          resolve(response.authResponse as fb.AuthResponse);
        }
      }, { config_id: configService.fbConfigId, auth_type: 'reauthenticate', scope: 'public_profile,email,manage_pages' }); // todo add manage pages
      
    });
  })
  }
}


export const facebookService = new FacebookService();
