import axios from '../configurations/request';
import { configService } from "./Config";

export class StripeService {
    async createCheckoutSession() {
        const baseUrl = configService.apiRoot;
        const req = await axios({
            method: 'POST',
            url: `${baseUrl}stripe/create-checkout-session`,
        });
        return await req.data;
    }
}

export const stripeService = new StripeService();