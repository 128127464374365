import React, { FunctionComponent, useState, useEffect } from "react";
import { View, Text, StyleSheet, Pressable, FlatList, TextInput } from 'react-native';
import { CommentDto } from "../../entities/interfaces";
import { commentService } from "../../services/Comment";
import { commentCategory } from "../../entities/CommentCategory";



export const CommentScreen: FunctionComponent<any> = ({ route, navigation: {goBack} }) => {
  const { params } = route;
  //const { id, message } = params;

  const [message, setMessage] = useState(params.message);
  const [botReply, setBotReply] = useState(params.editedBotReply || params.botReply);

  const saveEditedBotReply = async () => {
    return await commentService.saveEditedBotReply(params.id, botReply);
  }

  const postBotReply = async () => {
    await commentService.saveEditedBotReply(params.id, botReply);
    return await commentService.postBotReply(params.id);
  }

  return (
    <View style={styles.commentView}>
      <Text>Comment: {message}</Text>
      <TextInput style={styles.textInput} value={botReply} onChangeText={setBotReply}></TextInput>
      <View style={styles.actionRow}>
        <Pressable style={styles.button} onPress={goBack}>
          <Text>CANCEL</Text>
        </Pressable>
        <Pressable style={styles.button} onPress={() => {setBotReply(params.botReply)}}>
          <Text>Revert</Text>
        </Pressable>
        <Pressable style={styles.button} onPress={saveEditedBotReply}>
          <Text>SAVE WITHOUT POSTING</Text>
        </Pressable>
        <Pressable style={styles.button} onPress={postBotReply}>
          <Text>SAVE & POST</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  actionRow: {
    flexDirection: 'row'
  },
  commentView: {
    backgroundColor: '#ddd',
    padding: 10,
    margin: 25,
  },
  textInput: {
    backgroundColor: "#fff",
    padding: 5,
    marginTop: 10
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10
  }

});

