import React, { FunctionComponent } from "react";
import { View, Text, StyleSheet, Image } from 'react-native';


import { createNativeStackNavigator } from '@react-navigation/native-stack';


const Stack = createNativeStackNavigator();

export const SidebarHeader: FunctionComponent<any> = ({ route, navigation }) => {




  return (
    <View style={styles.main}>
        <Image
        style={styles.logo}
        source={{ uri: 'https://static.aicheetah.io/resources/logo.png'}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    minHeight: '128px',
    backgroundColor: '#11112c',
    padding: '8px',
    layout: 'column'
  },
  logo: {
    width: 'auto',
    height: undefined,
    aspectRatio: 1.62,
  }

});

