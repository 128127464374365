import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'


// Add a request interceptor
axios.interceptors.request.use(
  async config => {
    const token = await AsyncStorage.getItem('jwt');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export default axios;