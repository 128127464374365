import {ENV} from '@env';

export class ConfigService {
    get apiRoot() {
        console.log('local env:', ENV)
        if (ENV === 'local') {
            return 'http://localhost:3000/';
        }
        return 'https://esgpi9nnnd.execute-api.us-east-1.amazonaws.com/dev/';
    }

    get fbConfigId() {
        return '1393053344769263';
    }
}

export const configService = new ConfigService();

//https://esgpi9nnnd.execute-api.us-east-1.amazonaws.com/dev/