import axios from '../configurations/request';
import { LoginDto, UserLoginInfoDto } from "../entities/interfaces";
import { configService } from "./Config";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider } from "../modules/context/AuthContext";
import { facebookService } from "./Facebook";
import { useToast } from 'native-base';
import ReactGa from 'react-ga4';

export class UserService {
  async fbLogin(userID: string, access_token: string): Promise<LoginDto> {
    console.log('FB login')
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}users/login/fb`,
      data: {
        userID, access_token
      }
    });
    await AsyncStorage.setItem('jwt', req.data.jwt)
    return req.data;
  }

  async emailRegister(email: string, password: string): Promise<LoginDto> {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-855676182/oka0CKHxp3AQlqqCmAM',
      'value': 7,
      'currency': 'USD',
    });

    console.log('Email register')
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}users/email/${email}`,
      data: {
        password
      }
    });
    await AsyncStorage.setItem('jwt', req.data.jwt)
    return req.data;
  }

  async emailLogin(email: string, password: string): Promise<LoginDto> {
    console.log('Email login')
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}users/login/email`,
      data: {
        email, password
      }
    });
    await AsyncStorage.setItem('jwt', req.data.jwt);
    return req.data;
  }

  async tokenExchange(userId: string): Promise<LoginDto> {
    console.log('Token exchange')
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'PUT',
      url: `${baseUrl}users/login/token/${userId}`,
    });
    await AsyncStorage.setItem('jwt', req.data.jwt);
    return req.data;
  }

  // POST 'fb/connect/:_id'
  async fbConnect(_id: string) {

    const fbCreds = await facebookService.getFbCreds();
    console.log('FB Connect', fbCreds);
    const { accessToken: access_token, userID } = fbCreds;
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}users/fb/connect/${_id}`,
      data: {
        userID, access_token
      }
    });


    await AsyncStorage.setItem('jwt', req.data.jwt)
    return req.data;
  }

  async updatePassword(email: string, code: string, newPassword: string) {
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}users/password`,
      data: {
        email, code, newPassword
      }
    });
  }

  async checkoutCart(id: string, cartForm: any) {
    const baseUrl = configService.apiRoot;
    const {ccNum, ccExp, ccCvv, subscriptionLevel} = cartForm;
    const req = await axios({
      method: 'PUT',
      url: `${baseUrl}users/cart/${id}`,
      data: {
        ccNum, ccExp, ccCvv, subscriptionLevel
      }
    });
    return req;
  }

  async startPasswordReset(email: string) {
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'PUT',
      url: `${baseUrl}users/password`,
      params: {
        email
      }
    });
  }

}


export const userService = new UserService();
