import { Text } from 'native-base';
import React from 'react';
import moment from 'moment';



export const DateFmt = (data: any) => {
  const {dateTime, fmt, style} = data;
  let pretty;
  if (fmt === 'relative') {
    console.log(dateTime)
    pretty = moment(dateTime).fromNow();
  } else {
    pretty = moment(dateTime).format('MMM Do, YYYY')
  }
  
  return (
    <Text style={style}>{pretty}</Text>
  );
};