import axios from '../../configurations/request';
import { configService } from '../../services/Config';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Pressable } from 'react-native';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Layout } from '../components/Layout';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, Container, FormControl, Icon, Image, Input, Link, Spacer, Square, Stack, Text, useToast, WarningOutlineIcon } from 'native-base';
import { UserService, userService } from '../../services/User';
import { MaterialIcon } from '../components/Icon';
import { AuthContext } from '../context/AuthContext';


export const UpdatePasswordScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const params = route.params || {};

  useEffect(() => {
    if (!params.email || !params.code) {
      toast.show({description: 'Required params are missing.', duration: null});
      params.email = '';
      params.code = '';
    }
  }, [params.email, params.code])
  


  const [newPasswordVal, setNewPasswordVal] = useState('');

  const updatePassword = async () => {
    const {email, code} = params;
    try {
      await userService.updatePassword(email, code, newPasswordVal);
      toast.show({description: "Your password was updated. Please login to continue.", duration: null});
      setTimeout(() => {
        window.location.replace('/Login');
      }, 2000)
      
    } catch(e) {
      toast.show({description: "Your reset link is expired or invalid"})
    }
  }

  return (
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={170}>


          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Text fontSize='3xl' style={styles.heading}>
            Reset your password
          </Text>
        </Square>

        <Square w={360}>

          <FormControl isRequired>
            <Stack mx="4">
              <FormControl.Label><Text style={styles.formLabel}>New Password</Text></FormControl.Label>
              <Input type="password" w="full"
                color={colors.TEXT_WHITE}
                value={newPasswordVal} onChangeText={setNewPasswordVal}
              />
              <FormControl.HelperText>
                Enter a new password to continue
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please enter a valid new password
              </FormControl.ErrorMessage>


            </Stack>
          </FormControl>
          
          <Box w="full" p="16px">
            <Button w="full" onPress={() => updatePassword()}>Set your new password</Button>
          </Box>
        </Square>
        <Square w={360}>
          <Text color={colors.TEXT_WHITE}>
            Remembered your password? &nbsp;
            <Link color='primary.600' href='Login'>
              Sign In
            </Link>
          </Text>
        </Square>
      </Square>
    </Center>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 20,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  }
});