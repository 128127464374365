import {
  View,
  Text,
  FlatList,
  VStack,
  Button,
  useToast,
  Input,
  ScrollView,
  HStack,
  Spinner,
  Spacer,
} from "native-base";
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  ListRenderItem,
  StyleSheet,
} from "react-native";
import { colors, defaultStyles } from "../../styles/theme.style";
import { Layout } from "../components/Layout";
import { AuthContext, AuthState } from "../context/AuthContext";
import { ConversationObj, Message, cheetahService } from "../../services/Chetah";
import { useResponsiveSize } from "../helpers/ResponsiveSize";
import { ConversationList } from "../components/ConversationList";


export const ChatGPTTerminalScreen: FunctionComponent<any> = ({
  route,
  navigation,
}) => {
  const toast = useToast();
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [messages, setMessages] = useState<Message[]>([]);
  const [userPrompt, setUserPrompt] = useState("");
  const [settingsId, setSettingsId] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [loading, setLoading] = useState<Boolean>(false);
  const [conversations, setConversations] = useState<ConversationObj[]>([]);

  const loadConversations = () => {
    if (!settingsId) {
      return;
    }

    (async () => {
      const _conversations = await cheetahService.getConversations(settingsId);
      setConversations(_conversations);
    })();
  }

  const loadConversationId = () => {
    setConversationId(route?.params?.conversationId);
  };

  const loadConversation = () => {
    console.log('load conv', settingsId, conversationId);
    if (!settingsId || !conversationId) {
      return;
    }

    (async() => {
      const _conversation = await cheetahService.getConversation(settingsId, conversationId);
      setMessages(_conversation.messages);
    })();
  };

  const loadSettingsId = () => {
    if (user?._id) {
      setSettingsId(user._id);
    }
  };

  const newConversation = () => {
    navigation.navigate('IdeaExchange');
    setConversationId('');
    setMessages([]);
  }

  const submitPrompt = async () => {
    setLoading(true);
    const newConversation = !conversationId;

    //
    let conversationObj;
    if (newConversation) {
      conversationObj = await cheetahService.createConversation(
        settingsId,
        userPrompt
      );

      navigation.setParams({
        conversationId: conversationObj._id,
      });

      loadConversations();
    } else {
      conversationObj = await cheetahService.addToConversation(
        settingsId,
        conversationId,
        userPrompt
      );
    }

    setUserPrompt('');
    setMessages(conversationObj.messages);
    setLoading(false);
  };

  const renderConversation: ListRenderItem<any> = ({
    item,
    index,
  }: {
    item: Message;
    index: number;
  }) => (
    <VStack width={"full"}>
      {/** todo replace with an icon of cheetah / person */}
      <HStack style={index % 2 !== 0 ? styles.oddBox : styles.evenBox}>
        {index % 2 !== 0 ? (
          <Text style={styles.p}>AI:</Text>
        ) : (
          <Text style={styles.p}>YOU:</Text>
        )}
        <Text style={styles.p}>{item?.content}</Text>
      </HStack>
    </VStack>
  );

  useEffect(loadConversationId, [route?.params?.conversationId]);
  useEffect(loadConversations, [settingsId]);
  useEffect(loadConversation, [conversationId, settingsId])
  useEffect(loadSettingsId, [user]);

  const { isDeviceWidth490_below } = useResponsiveSize();

  return (
    <Layout>
      <View style={styles.container}>
        <HStack flex="1" mx={'0'} padding={0}>
          <HStack width={"1/6"}>
            <ScrollView h="full" style={styles.leftColumn}>
              <Text style={styles.labelText}>History:</Text>
              <ConversationList conversations={conversations}/>
            </ScrollView>
          </HStack>
          <VStack flex={'1'}>
          <HStack mx="4">
            <Text style={styles.h4}>Chat GPT Terminal</Text>
            {conversationId ? <View><Spacer /><Button size={'sm'} onPress={newConversation}>+ New</Button></View> : ''}
          </HStack>

          <ScrollView flex={'1'} padding={5}>
            <VStack >
              <FlatList flex={"1"} height={"full"} data={messages} renderItem={renderConversation} />
              {loading && <Spinner></Spinner>}
              <VStack style={styles.labelStack} >
                <Text style={styles.labelText} marginTop={5}>Enter your message:</Text>
                <Input style={styles.inputGeneral} value={userPrompt} onChangeText={setUserPrompt} onSubmitEditing={submitPrompt} />
                <View width={"1/6"} marginTop={4}><Button onPress={submitPrompt}>Submit</Button></View>
              </VStack>
            </VStack>
          </ScrollView>
          </VStack>
        </HStack>
      </View>
    </Layout>
  );
};

const borderRadius = 20;

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles, {
    iframe: {
      border: "none",
      backgroundColor: "white",
    },
    stepBox: {
      backgroundColor: colors.BK_COLOR_VVLT,
    },
    oddBox: {
      backgroundColor: colors.BK_COLOR_VVLT,
    },
    evenBox: {},
    labelStack: {},
    leftColumn: {
    },
  })
);
