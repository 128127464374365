import axios from '../configurations/request';
import { configService } from "./Config";

export class FeedbackService {

      async submit(userID: String, feedback: string): Promise<any> {
        
        const baseUrl = configService.apiRoot;
        const req = await axios({
          method: 'POST',
          url: `${baseUrl}communications/feedback/${userID}`,
          data: {feedback}
        });

        return req.data;
      }

    
}

export const feedbackService = new FeedbackService();