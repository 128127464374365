export class CommentCategory {
  getCategoryTitle(key: number) {
    console.log('get cat: ', key)
    const categories = [
      '',
      'postitive product feedback',
      'negative product feedback',
      'postitive customer service feedback',
      'negative customer service feedback',
      'general complaint',
      'feature request',
      'general question',
      'sales feedback',
      'other',
    ];
    return categories[+key];
  }
}

export const commentCategory = new CommentCategory();