import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";

import {
  View,
  Text,
  StyleSheet,
  Pressable,
  FlatList,
  TextInput,
} from "react-native";

import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { SidebarHeader } from "./SidebarHeader";
import { MaterialIcon } from "./Icon";
import { colors } from "../../styles/theme.style";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Spacer,
  VStack,
} from "native-base";
import { Menu as DropMenu } from "native-base";
import { AuthContext } from "../context/AuthContext";
import { useNavigation } from "@react-navigation/native";
import { useResponsiveSize } from "../helpers/ResponsiveSize";

const userLoggedIn = true;

export const Layout: FunctionComponent<any> = ({ children }) => {
  const navigation = useNavigation();
  //https://oblador.github.io/react-native-vector-icons/
  const iconComp = (name: string) => {
    return <MaterialIcon size="large" color="white" name={name} />;
  };

  const context = useContext(AuthContext);

  const { isTabletOrMobileDevice, isDeviceWidth490_below } =
    useResponsiveSize();

  const [menuIsExpanded, setMenuIsExpanded] = useState(!isTabletOrMobileDevice);

  const handleMenuOpen = () => {
    setMenuIsExpanded(!menuIsExpanded);
  };

  return (
    <View style={styles.outerContainer}>
      <Sidebar
        style={{
          ...styles.sidebar,
          position: isDeviceWidth490_below ? "fixed" : "static",
          transform:
            isDeviceWidth490_below && !menuIsExpanded
              ? "translateX(-100%)"
              : "unset",
        }}
        backgroundColor="#11112c"
      >
        <SidebarHeader />

        <Menu>
          <Pressable onPress={() => navigation.navigate("Dashboard")}>
            <MenuItem style={styles.menuItem} icon={iconComp("home")}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>Home / History</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable onPress={() => navigation.navigate("IdeaExchange")}>
            <MenuItem style={styles.menuItem} icon={iconComp("head-lightbulb")}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>ChatGPT Terminal</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable onPress={() => navigation.navigate("BlogAi")}>
            <MenuItem style={styles.menuItem} icon={iconComp("wizard-hat")}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>Article / Blog Generator</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable onPress={() => navigation.navigate("ArticleTools")}>
            <MenuItem style={styles.menuItem} icon={iconComp("note-edit")}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>AI Article / Blog Editor</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable onPress={() => navigation.navigate("Setup")}>
            <MenuItem
              style={styles.menuItem}
              icon={iconComp("store-settings-outline")}
            >
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>Account Settings</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable onPress={() => navigation.navigate("ContactUs")}>
            <MenuItem style={styles.menuItem} icon={iconComp("help-box")}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>Help / Feedback</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable
            disabled={true}
            onPress={() => navigation.navigate("Pages")}
          >
            <MenuItem icon={iconComp("facebook")} style={styles.menuItem}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>Facebook - Coming soon</Text>
              )}
            </MenuItem>
          </Pressable>

          <Pressable
            disabled={true}
            onPress={() => navigation.navigate("Pages")}
          >
            <MenuItem icon={iconComp("twitter")} style={styles.menuItem}>
              {menuIsExpanded && (
                <Text style={styles.sidebarText}>Twitter - Coming soon</Text>
              )}
            </MenuItem>
          </Pressable>
        </Menu>
      </Sidebar>
      <VStack style={styles.rightCol}>
        <Box style={styles.headerBar}>
          <Center p={5}>
            {!isDeviceWidth490_below ? (
              <Pressable onPress={handleMenuOpen}>
                <MaterialIcon name={"menu"} size="large" color="#fff" />
              </Pressable>
            ) : (
              <DropMenu
                trigger={(triggerProps) => {
                  return (
                    <Button {...triggerProps} variant={"ghost"}>
                      Account
                    </Button>
                  );
                }}
              >
                <DropMenu.Item onPress={context.logout}>Logout</DropMenu.Item>
              </DropMenu>
            )}
          </Center>
          {!isDeviceWidth490_below && (
            <>
              <Spacer /> <Spacer />
            </>
          )}

          <Center style={styles.headerBarRight}>
            <IconButton
              variant={"ghost"}
              icon={<MaterialIcon name="bell" size="large" color="#fff" />}
            />
          </Center>

          <Center style={styles.headerBarRight}>
            {isDeviceWidth490_below ? (
              <Pressable onPress={handleMenuOpen}>
                <MaterialIcon name={"menu"} size="large" color="#fff" />
              </Pressable>
            ) : (
              <DropMenu
                trigger={(triggerProps) => {
                  return (
                    <Button {...triggerProps} variant={"ghost"}>
                      Account
                    </Button>
                  );
                }}
              >
                <DropMenu.Item onPress={context.logout}>Logout</DropMenu.Item>
              </DropMenu>
            )}
          </Center>
        </Box>
        {children}
      </VStack>
    </View>
  );
};

const styles = StyleSheet.create({
  rightCol: {
    flexDirection: "column",
    flex: 1,
  },
  headerBar: {
    backgroundColor: colors.HEADER_BK,
    height: "48px",
    flexDirection: "row",
  },
  headerBarLeft: {
    flex: 1,
  },
  headerBarRight: {
    flex: 1,
  },
  outerContainer: {
    flexDirection: "row",
    height: "100vh",
  },
  sidebarContainer: {
    position: "relative",
  },
  sidebarText: {
    color: "white",
    paddingLeft: 10,
  },
  mainContainer: {
    position: "relative",
    backgroundColor: "red",
    width: "20vh",
  },
  menuItem: {
    backgroundColor: colors.BK_THEME_COLOR,
  },
  button: {
    backgroundColor: "#3578e5",
    padding: 10,
    margin: 10,
  },
  sidebar: {
    height: "100vh",
    width: "fit-content",
    minWidth: 0,
    zIndex: 99,
  },
  navCon: {},
});
