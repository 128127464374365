import React, { FunctionComponent, useContext, useState } from "react";
import { Text, StyleSheet, Image } from 'react-native';
import { useNavigation } from "@react-navigation/native";

import { AspectRatio, Button, Center, HStack, Spacer, Switch, useToast, VStack } from "native-base";
import { colors } from "../../styles/theme.style";
import { UserService, userService } from "../../services/User";
import { AuthContext } from "../context/AuthContext";
import { PageService } from "../../services/Page";



export const MediaCard: FunctionComponent<any> = ({ page = {} }) => {
  const toast = useToast();
  const navigation = useNavigation();
  const context = useContext(AuthContext);
  const stackWidth = '268px';
  const stackHeight = '340px';
  const avitarWidth = '64px';

  const [isAuto, setAuto] = useState(page.auto);

  const toggleAutoSwitch = () => {
    setAuto((oldValue: boolean) => {
      new PageService().setPageSetting(page.id, { auto: !oldValue });
      return !oldValue;
    });
  }

  return (
    <VStack w={stackWidth} h={stackHeight} style={styles.card} backgroundColor={colors.BK_COLOR_VD}>
        <AspectRatio w={stackWidth} ratio={94 / 35} style={styles.ratio}>
          <Image source={{ uri: 'https://static.aicheetah.io/resources/imgsoon.png' }} />
        </AspectRatio>
        <Center>
          <AspectRatio top={-32} style={styles.avitar} w={avitarWidth} ratio={1 / 1} >
            <Image source={{ uri: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Facebook_icon_2013.svg' }} />
          </AspectRatio>
        </Center>
        <Center><Text style={styles.title}>{page.name}</Text></Center>
        <Spacer />
        <Center><Text style={styles.info}>{page.category}</Text></Center>
        <Spacer />
        <Center>
          <HStack>
          <Button width={81} height={22} style={styles.btn} variant={'outline'} onPress={() => {
            navigation.navigate('Page', page)
          }}>
            <Text style={styles.btnText}>{'VIEW'}</Text>
          </Button>
          <Center marginLeft={5}>
            <Switch isChecked={isAuto} onValueChange={toggleAutoSwitch}/><Text style={styles.info}>Auto reply</Text>
          </Center>
          
          </HStack>
        </Center>
        <Spacer />
      </VStack>
  );
};

// 940 X 350 is the pixel size of a fb page images banner

const borderRadius = 20;

const styles = StyleSheet.create({
  card: {
    borderRadius,

  },
  emptyCard: {
    borderRadius,
    border: `1px dashed ${colors.BRAND_ACCENT}`
  },
  btn: {
    paddingVertical: 24,
    paddingHorizontal: 11,
    borderRadius: 30,
    borderColor: colors.BRAND_ACCENT
  },
  emptyBtn: {
    paddingHorizontal: 20,
    borderRadius: 30,
    borderColor: colors.BRAND_ACCENT
  },
  btnText: {
    color: colors.TEXT_WHITE
  },
  title: {
    color: colors.TEXT_WHITE,
    fontSize: 22,
    fontWeight: '400',

  },
  info: {
    color: colors.TEXT_GREY,
    fontSize: 14,
    fontWeight: '400',

  },
  avitar: {
    borderRadius,
    overflow: 'hidden',
    border: `2px solid ${colors.BRAND_ACCENT}`
  },
  ratio: {
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    overflow: 'hidden'
  },
  logo: {
    width: 'auto',
    height: undefined,
    aspectRatio: 1.62,
  }

});

