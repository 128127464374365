import axios from '../configurations/request';
import { configService } from './Config';

export class ArticlePost {
  constructor(article: string) {
    this.article = article;
  }
  article: string;
  get shortContent() {
    try {
      return this.article.substring(0, 200);
    } catch (e) {
      return '';
    }
  }

  get fullContent() {
    return this.article || '';
  }
}

type Roles = "user" | "assistant" | "system"

export interface Message {
  role: Roles;
  content: string;
}

export interface ConversationObj {
  accountId: string;
  title: string;
  messages: Array<Message>
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface ConversationPrompt {
  label: string;
  messages: Array<Message>;
  id: string;
}

export class CheetahService {

  async createConversation(settingsId: string, prompt: string): Promise<ConversationObj> {
    console.log('start new conversation');
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}cheetah/conversations/${settingsId}`,
      data: {
        prompt
      }
    });

    return req.data;
  }

  async addToConversation(settingsId: string, conversationId: string, prompt: string): Promise<ConversationObj> {
    console.log('add to conversation');
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'PUT',
      url: `${baseUrl}cheetah/conversations/${settingsId}/${conversationId}`,
      data: {
        prompt
      }
    });

    return req.data;
  }

  async getConversations(settingsId: string,): Promise<ConversationObj[]> {
    console.log('get conversations');
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}cheetah/conversations/${settingsId}`
    });

    return req.data;
  }

  async getConversation(settingsId: string, conversationId: string): Promise<ConversationObj> {
    console.log('get conversation');
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}cheetah/conversations/${settingsId}/${conversationId}`
    });

    return req.data;
  }

  async fetchConversationPrompts(): Promise<ConversationPrompt[]> {
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}cheetah/conversationPrompts`
    });

    return req.data;
  }

  async createConversationWithPrompt(settingsId: string, promptId: string, userInput: string): Promise<ConversationObj> {
    console.log('start new conversation with prompt Id');
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}cheetah/conversationPrompts/${promptId}/${settingsId}`,
      data: {
        userInput
      }
    });

    return req.data;
  }

  async generateBlogIdeas(settingsId: string, inputGeneral: string): Promise<string> {
    console.log('generate Ideas')
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}cheetah/blog/ideas/${settingsId}`,
      data: {
        inputGeneral
      }
    });

    return req.data;
  }

  async generateOutline(settingsId: string, outlineTitle: string, inputGeneral: string, additionalInfo: string): Promise<string> {

    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}cheetah/blog/outline/${settingsId}`,
      data: {
        outlineTitle,
        inputGeneral,
        additionalInfo
      }
    });

    return req.data;
  }

  async getItems(settingsId: string, limit = 5, offset = 0) {
    console.log('fetch items:', settingsId);
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      params: {
        limit, offset
      },
      url: `${baseUrl}cheetah/blog/items/${settingsId}`,
    });

    return req.data;
  }

  async fetchItem(settingsId: string, id: string) {
    console.log('fetch item:', id);
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}cheetah/blog/items/${settingsId}/${id}`,
    });

    return req.data;
  }

  async generateBlogFromOutline(settingsId: string, inputGeneral: string, verbosity: string, title: string, outline: string, additionalInfo: string): Promise<string> {

    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}cheetah/blog/fromOutline/${settingsId}`,
      data: {
        title,
        outline,
        inputGeneral,
        additionalInfo,
        verbosity,
      }
    });

    const blogId: string = req.data;
    return blogId;
  }



  async pollForBlog(settingsId: string, blogId: string): Promise<string> {
    console.log("poll for blog", blogId)
    
    const firstTry = await this.fetchItem(settingsId, blogId);
    if (firstTry.finished) {
      return firstTry.messageContent;
    }


    let article = '';
    let count = 0;
    return await new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        const _article = await this.fetchItem(settingsId, blogId);
        if (count > 20) {
          clearInterval(interval);
          reject('Unknown error');
        }
        count++;
        if (_article.finished) {
          article = _article.messageContent;
          clearInterval(interval);
          return resolve(article);
        }
      }, 5000)
    });
  }

  async textActions(settingsId: string, text: string, action: string, options: any) {
    console.log('text actions', settingsId)
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'POST',
      url: `${baseUrl}cheetah/blog/textActions/${settingsId}`,
      data: {
        text, action, options
      }
    });

    const result: string = req.data;
    console.log(result);
    return result
  }


}

export const cheetahService = new CheetahService();