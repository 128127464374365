import axios from '../configurations/request';
import { configService } from "./Config";

export class PostService {
    async getPosts(pageId: string) {
        const baseUrl = configService.apiRoot;
        const req = await axios({
            method: 'GET',
            url: `${baseUrl}posts/page/${pageId}`
        });
        return await req.data;
    }
}