import React, { FunctionComponent, useState, useEffect } from "react";
import { View, Text, StyleSheet, Pressable, FlatList } from 'react-native';
import { CommentDto } from "../../entities/interfaces";
import { CommentService } from "../../services/Comment";
import { commentCategory } from "../../entities/CommentCategory";


let loaded = false;

export const PostScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const { params } = route;
  console.log(params)
  const { id, message } = params;
  const postId = id;

  const [unrepliedComments, setUnrepliedComments] = useState([] as CommentDto[]);
  const [repliedComments, setRepliedComments] = useState([] as CommentDto[]);

  const partition = (array: Array<any>, isValid: Function) => {
    return array.reduce(([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    }, [[], []]);
  }

  const load = () => {
    if (loaded) { // prevent loop
      return;
    };

    new CommentService().getComments(postId).then((res: CommentDto[]) => {

      const [replied, unreplied] = partition(res, (e: CommentDto) => e.resolved);

      //@ts-ignore
      setUnrepliedComments([...unreplied]);
      //@ts-ignore
      setRepliedComments([...replied]);
      loaded = true;

      //@ts-ignore
      console.log('load comments')
      console.log(res);
    });
  }

  useEffect(() => {
    load();
  });


  return (


    <View style={styles.container}>
      <Text>Post: {message}</Text>
      <FlatList data={unrepliedComments} renderItem={({ item }) =>
        <View style={styles.commentView}>
          <Text>FB Comment: {item.message}</Text>
          <Text>Category: {commentCategory.getCategoryTitle(item.commentCategory)}</Text>
          <Text>Suggested Reply: {item.editedBotReply || item.botReply}</Text>
          <View style={styles.actionRow}>
            <Pressable style={styles.button} onPress={() => {navigation.navigate('Comment', item)}}>
              <Text>Edit</Text>
            </Pressable>
            <Pressable style={styles.button}>
              <Text>Send Reply Comment - todo</Text>
            </Pressable>
          </View>
        </View>}
      />

      <Text>~~~~~~~~~~~~</Text>
      <FlatList data={repliedComments} renderItem={({ item }) =>
        <View style={styles.commentView}>
          <Text>FB Comment: {item.message}</Text>
          <Text>Category: {commentCategory.getCategoryTitle(item.commentCategory)}</Text>
          <Text>Suggested Reply: {item.botReply}</Text>
          <View style={styles.actionRow}>
            <Pressable style={styles.button}>
              <Text>Edit</Text>
            </Pressable>
            <Pressable style={styles.button}>
              <Text>Send</Text>
            </Pressable>
          </View>
        </View>}
      />

    </View>
  );
};

const styles = StyleSheet.create({
  actionRow: {
    flexDirection: 'row'
  },
  commentView: {
    backgroundColor: '#ddd',
    padding: 8,
    margin: 8,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10
  }
});

