import axios from '../configurations/request';
import { SettingsDto } from "../entities/interfaces";
import { configService } from "./Config";

export class SettingsService {
    async updateSettings(settingsId: String, settings: SettingsDto): Promise<SettingsDto> {
        console.log('update settings')
        const baseUrl = configService.apiRoot;
        const req = await axios({
          method: 'PATCH',
          url: `${baseUrl}settings/${settingsId}`,
          data: {
            settings
          }
        });

        return req.data;
      }

      async getSettings(settingsId: String): Promise<SettingsDto> {
        console.log('get settings')
        const baseUrl = configService.apiRoot;
        const req = await axios({
          method: 'GET',
          url: `${baseUrl}settings/${settingsId}`,
        });

        return req.data;
      }

    
}

export const settingsService = new SettingsService();