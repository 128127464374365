import axios from '../configurations/request';
import { PageSettings } from "../entities/interfaces";
import { configService } from "./Config";

export class PageService {
  async getPages(userId: string|number): Promise<Array<any>> {
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}pages/user/${userId}`
    });
    return req.data;
  }

  async setPageSetting(pageId: string, pageSettings: PageSettings) {
    const baseUrl = configService.apiRoot;
    return axios({
      method: 'PUT',
      url: `${baseUrl}pages/settings/${pageId}`,
      data: pageSettings
    });
  }
}