import {
  View,
  Text,
  Pressable,
  FlatList,
  Spacer,
  Center,
  VStack,
  HStack,
  Button,
  Box,
  Select,
  Input,
  ScrollView,
  TextArea,
} from "native-base";
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import { StyleSheet } from "react-native";
import { feedbackService } from "../../services/Feedback";
import { colors, defaultStyles } from "../../styles/theme.style";
import { Layout } from "../components/Layout";
import { AuthContext, AuthState } from "../context/AuthContext";
import { useResponsiveSize } from "../helpers/ResponsiveSize";

let loaded = false;

export const ContactUsScreen: FunctionComponent<any> = ({
  route,
  navigation,
}) => {
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const fbUserID = user?.userID;
  const [feedback, setFeedback] = useState("");

  const load = () => {
    if (loaded || !fbUserID) {
      // prevent loop, prevent undefined call
      return;
    }
  };

  const submitFeedback = async () => {
    console.log(authState);
    if (!user) {
      throw "user _id is undefined";
    }

    await feedbackService.submit(user._id, feedback);
    setFeedback("");
    alert("Thank you, your message has been submitted");
  };

  useEffect(() => {
    load();
  });

  const { isDeviceWidth490_below } = useResponsiveSize();

  return (
    <Layout>
      <View style={styles.container}>
        <VStack>
          <HStack>
            <VStack>
              <Text style={styles.h4}>Feedback and customer support form</Text>
            </VStack>
            <Spacer />

            <Box></Box>
          </HStack>

          <Text style={styles.h4}>____________________________</Text>
          <Text style={styles.p}>
            Hi, thank you for using AiCheetah. I would love your feedback and
            also look forward to answering your questions you may have. Please
            enter your comments or questions. Please be sure to add a contact
            method in the note so we can reply.
          </Text>
          <Text style={styles.p}>
            Sincerely, Matthew Wilson - Founder of AiCheetah
          </Text>
        </VStack>
        <ScrollView flex="1" padding={isDeviceWidth490_below ? 4 : 10}>
          <HStack style={styles.inputHStack}>
            <VStack width={"full"} style={styles.labelStack}>
              <Text style={styles.labelText}>Your Question / Comments:</Text>

              <TextArea
                style={styles.inputGeneral}
                width={"full"}
                minH="sm"
                value={feedback}
                onChangeText={setFeedback}
              />
              <Pressable
                width={"fit-content"}
                style={styles.button}
                onPress={() => {
                  submitFeedback();
                }}
              >
                <Text>Submit</Text>
              </Pressable>
            </VStack>
          </HStack>
        </ScrollView>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles, {
    stepBox: {
      backgroundColor: colors.BK_COLOR_VVLT,
    },
  })
);
