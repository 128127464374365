
import { StyleSheet } from 'react-native';
import { FunctionComponent, useContext, useState } from 'react';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, FormControl, HStack, Image, Input, Link, Select, Spacer, Square, Stack, Text, useToast, VStack, WarningOutlineIcon } from 'native-base';
import { userService } from '../../services/User';
import { AuthContext, AuthProvider, AuthState } from '../context/AuthContext';

export const CartScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const [ccNum, setCcNum] = useState('');
  const [ccExp, setCcExp] = useState('');
  const [ccCvv, setCcCvv] = useState('');
  const [subscriptionLevel, setSubscriptionLevel] = useState('BASIC')
  const [working, setWorking] = useState(false);
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState
  const { user } = authState;

  const checkout = async () => {
    
    if (!user?._id) {
      console.error('user id is not defined while checking out cart');
      return;
    }

    const userId = user._id;

    setWorking(true);
    const cartForm = { ccNum, ccExp, ccCvv, subscriptionLevel }
    await userService.checkoutCart(userId, cartForm);
    await userService.tokenExchange(userId);
    window.location = '/'

  }

  return (
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={200}>


          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Text fontSize='md' style={styles.subheading}>
            30 Day No Obligation Free Trial
          </Text>
          <Text fontSize='md' style={styles.subheading}>
            After trial the cost is $7 a month.
          </Text>

          

        </Square>

        <Square w={360}>

          <FormControl isRequired>
            <Stack mx="4">
              <FormControl.Label><Text style={styles.formLabel}>Credit Card Number</Text></FormControl.Label>
              <Input type="text" w="full"
                color={colors.TEXT_WHITE}
                onChangeText={setCcNum} />
              <FormControl.HelperText>
                Enter your credit card number
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please use a valid credit card number
              </FormControl.ErrorMessage>

              <HStack>
                <VStack>
                  <FormControl.Label><Text style={styles.formLabel}>Expires:</Text></FormControl.Label>
                  <Input type="text" onChangeText={setCcExp} color={colors.TEXT_WHITE} />
                  <FormControl.HelperText>
                    MM/YY
                  </FormControl.HelperText>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid expiration date. Format: MM/YY
                  </FormControl.ErrorMessage>
                </VStack>
                <Spacer />
                <VStack>
                  <FormControl.Label><Text style={styles.formLabel}>Security Code:</Text></FormControl.Label>
                  <Input type="text" onChangeText={setCcCvv} color={colors.TEXT_WHITE} />
                  <FormControl.HelperText>
                    123
                  </FormControl.HelperText>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a 3 or 4 digit security code
                  </FormControl.ErrorMessage>
                </VStack>
              </HStack>
            </Stack>
          </FormControl>
          <Box w="full" p="16px">
            <Button w="full" isLoading={working} isLoadingText='Setting up your account' onPress={() => checkout()}>Start Trial</Button>
          </Box>
        </Square>
        <Square w={360}>

          <Text color={colors.TEXT_WHITE}>
            Already have an active account? &nbsp;
            <Link color='primary.600' href='/signin'>
              Sign in
            </Link>
          </Text>
        </Square>
      </Square>
    </Center>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 10,
  },
  subheading: {
    color: colors.TEXT_WHITE,
    fontWeight: '400',
    padding: 0,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  },
  selectBox: {

  }
});