import React, { FunctionComponent, useState, useEffect } from "react";
import { StyleSheet, ListRenderItem, Touchable, TouchableOpacity, TouchableHighlight } from 'react-native';
import { PostService } from '../../services/Post';
import { CommentDto, PageDto, PostDto } from '../../entities/interfaces'
import { PageService } from "../../services/Page";
import { commentService, CommentService } from "../../services/Comment";
import { Layout } from "../components/Layout";
import { View, Center, Text, Container, HStack, VStack, Pressable, FlatList, Box, Spacer, Avatar, Button, Switch, IconButton, Input, TextArea } from "native-base";
import { colors } from "../../styles/theme.style";
import { DateFmt } from "../components/DateFmt";
import { MaterialIcon } from "../components/Icon";

let loaded = false;
export const PageScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const { params } = route;
  console.log(params)
  const page: PageDto = params;
  const { id, name, managed } = page;


  const [isManaged, setManaged] = useState(managed);
  const [editingId, setEditingId] = useState();
  const [editedBotReply, setEditedBotReply] = useState('')
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [activePostId, setActivePostId] = useState('');

  const toggleManagedSwitch = () => {
    setManaged(oldValue => {
      new PageService().setPageSetting(id, { managed: !oldValue });
      return !oldValue
    });
  }

  const load = () => {
    if (loaded) { // prevent loop
      return;
    };

    new PostService().getPosts(id).then((res) => {
      //@ts-ignore
      setPosts([...res]);
      setActivePost(res[0]);
      loaded = true;
    });
  }

  useEffect(() => {
    load();
  });

  const setActivePost = async (post: PostDto) => {
    setActivePostId(post.id);
    const _comments = await new CommentService().getComments(post.id);
    setComments(_comments);
  }

  const editComment = async (item) => {
    setEditingId(item.id);
    setEditedBotReply(item.editedBotReply || item.botReply);
  }

  const saveEdits = async (id: string, edit: string) => {
    await commentService.saveEditedBotReply(id, edit);
    const index = comments.findIndex((comment) => {
      console.log(comment.id, id);
      return comment.id === id;
    });
    console.log(index)
    comments[index].editedBotReply = edit;
    console.log('comments')
    console.log(comments);
    setComments([]);
    setComments(comments);
    cancelEdit();
  }

  const resolveComment = async (item, val: boolean) => {
    await new CommentService().resolveComment(item.id, val);
    const index = comments.findIndex((comment) => {
      console.log(comment.id, item.id);
      return comment.id === item.id;
    });
    console.log(index)
    comments[index].resolved = val;
    console.log(comments);
    setComments([]);
    setComments(comments);
  }

  const sendReply = async (comment: CommentDto) => {
    const reply = await commentService.postBotReply(comment.id);
    const index = comments.findIndex((_comment) => {
      return _comment.id === comment.id;
    });
    comments[index].replyComment = reply;
    console.log(comments);
    setComments([]);
    setComments(comments);
  }

  const cancelEdit = () => {
    setEditingId(null);
  }

  const renderComment: ListRenderItem<CommentDto> = ({ item }) => (
    <TouchableHighlight>
      <Center flex={1}>
        <Box flex={1} style={styles.commentBox} w="full" backgroundColor={'red.300'}>
          <VStack flex={1}>
            <HStack flex={1}>
              <Avatar size={'sm'}>{item.from?.name?.substring(0, 2) || '?'}</Avatar>
              <VStack marginLeft={5} w="full">
                <Container flex={1}>
                  <Box style={styles.commentReplyBox}>
                    <Text style={styles.postBoxHeader}>{item.from?.name || 'Name Unknown'}</Text>
                    <Text style={styles.commentMessage}>{item.message}</Text>
                  </Box>
                </Container>
                <DateFmt dateTime={item.created_time} fmt="relative" style={styles.subText} />
                {/* Reply Section */}
                <VStack>
                  <HStack justifyContent={'flex-end'}>
                    {item.replyComment ?
                      /* Reply has been sent */
                      <Container w="full" flex={1}>
                        <HStack w="full" flex={1}>
                          <Box style={styles.commentReplyBox}>
                            <Text style={styles.postBoxHeader}>{item.editedBotReply ? 'You - Edited' : 'You - AI'}</Text>
                            <Text style={styles.commentMessage}>{item.editedBotReply || item.botReply}</Text>
                          </Box>
                        </HStack>
                        <DateFmt style={styles.subText} dateTime={item.replyComment.createdAt} fmt='relative'/>

                      </Container>
                      :
                      <>
                        {/* No reply send */}
                        <Container w="full" flex={1}>
                          {editingId === item.id ?
                            /** EDITING */
                            <HStack w="full">
                              <VStack>
                                <Button size='sm' marginRight={1} marginBottom={1} rightIcon={<MaterialIcon name="send" size="small" color="#fff" />} onPress={()=> {sendReply(item)}}>Send</Button>
                                <Button size='sm' marginRight={1} marginBottom={1} rightIcon={<MaterialIcon name="content-save-edit" size="small" color="#fff" />} onPress={() => saveEdits(item.id, editedBotReply)}>Save</Button>
                                <Button size="sm" marginRight={1} marginBottom={1} colorScheme={'secondary'} rightIcon={<MaterialIcon name="cancel" size="small" color="#fff" />} onPress={cancelEdit}>Cancel</Button>
                              </VStack>
                              <Box w="90%">
                                <TextArea w="85%"
                                  maxHeight={900}
                                  color={colors.TEXT_WHITE}
                                  value={editedBotReply} onChangeText={setEditedBotReply}
                                />
                              </Box>
                            </HStack>
                            :
                            /** NOT EDITING */
                            <>
                              {item.resolved ?
                                /** Not editing, Resolved */
                                <HStack w="full">
                                <VStack>
                                  <Button size="sm" marginRight={1} marginBottom={1} colorScheme={'secondary'} rightIcon={<MaterialIcon name="comment-eye" size="small" color="#fff" />} onPress={() => resolveComment(item, false)}>Unignore</Button>
                                </VStack>
                                <Box style={styles.commentDraftBox}>
                                  <Text style={styles.postBoxHeader}>AI Draft</Text>
                                  <Text style={styles.commentDraftText}>{item.editedBotReply || item.botReply}</Text>
                                </Box>
                              </HStack>
                                :
                                /** Not editing, Not resolved */
                                <HStack w="full">
                                  <VStack>
                                    <Button size='sm' marginRight={1} marginBottom={1} rightIcon={<MaterialIcon name="send" size="small" color="#fff" /> } onPress={()=> {sendReply(item)}} >Send</Button>
                                    <Button size="sm" marginRight={1} marginBottom={1} colorScheme={'secondary'} rightIcon={<MaterialIcon name="pencil" size="small" color="#fff" />} onPress={() => editComment(item)}>Edit</Button>
                                    <Button size="sm" marginRight={1} marginBottom={1} colorScheme={'secondary'} rightIcon={<MaterialIcon name="comment-off" size="small" color="#fff" />} onPress={() => resolveComment(item, true)}>Ignore</Button>
                                  </VStack>
                                  <Box style={styles.commentDraftBox}>
                                    <Text style={styles.postBoxHeader}>AI Draft</Text>
                                    <Text style={styles.commentDraftText}>{item.editedBotReply || item.botReply}</Text>
                                  </Box>
                                </HStack>}
                            </>
                          }
                        </Container>
                      </>
                    }
                  </HStack>
                </VStack>
              </VStack>
            </HStack>


          </VStack>

        </Box>
      </Center>
    </TouchableHighlight>
  );

  const renderPost: ListRenderItem<PostDto> = ({ item: post }) => (

    <TouchableHighlight onPress={() => setActivePost(post)}>
      <Center flex={1} marginLeft={5} marginRight={5}>
        <Box style={post.id === activePostId ? styles.selectedPostBox : styles.postBox}
          flex={1} w="full">
          <HStack flex={1}>
            <Text style={styles.postBoxHeader}>{name}</Text>
            <Spacer />
            <DateFmt style={styles.postBoxHeader} dateTime={post.created_time}></DateFmt>
          </HStack>

          <Text style={styles.postMessage} numberOfLines={5}>{post.message}</Text>
        </Box>
      </Center>
    </TouchableHighlight>
  );
  /*
  <FlatList data={post.comments} renderItem={renderComment} />
    */

  return (
    <Layout >
      <View style={styles.container}>
        <Text>Managing posts for the page: {name}</Text>
        <HStack flex={1}>
          <Container style={styles.postCol} w={'2/5'}>
            <VStack flex={1}>
              <HStack>
                <Text style={styles.header}>Posts</Text>
                <Spacer />

              </HStack>
              <View flex={1}>
                <FlatList flex={1} data={posts} renderItem={renderPost} />
              </View>
            </VStack>
          </Container>
          <Container flex={1} style={styles.postCol} w="3/5">
            <VStack flex={1} w="full">
              <Text style={styles.header}>Comments</Text>
              <View flex={1}>
                <FlatList flex={1} data={comments} renderItem={renderComment} />
              </View>
            </VStack>
          </Container>
        </HStack>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  postCol: {
    backgroundColor: colors.BK_COLOR_LT,
    margin: 10,
    borderRadius: 20,
  },
  postBox: {
    backgroundColor: colors.BK_COLOR_VLT,
    borderRadius: 10,
    margin: 15,
    padding: 15,
  },
  selectedPostBox: {
    backgroundColor: colors.HEADER_BK,
    borderRadius: 10,
    margin: 15,
    padding: 15,
  },
  postBoxHeader: {

    fontSize: 14,
    lineHeight: 20,
    color: colors.TEXT_WHITE,
  },
  header: {
    fontSize: 24,
    lineHeight: 32,
    color: colors.TEXT_WHITE,
    margin: 10,
    marginLeft: 30,
  },
  switchLabel: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: 300,
    color: colors.TEXT_WHITE,
    margin: 10,
  },
  commentBox: {
    backgroundColor: colors.BK_COLOR_VVLT,
    padding: 15,
    marginTop: 18,
  },
  innerCommentBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    padding: 12,
  },
  commentReplyBox: {
    width: '450px',
    backgroundColor: colors.BK_THEME_COLOR,
    padding: 12,
  },
  commentDraftBox: {
    width: '450px',
    padding: 12,
    border: '1px dashed #585C67',
    borderRadius: 36,
  },
  commentDraftText: {
    color: '#D2D3D6',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
  },
  subText: {
    color: colors.TEXT_GREY,
    fontWeight: '500',
    fontSize: 11,
    lineHeight: 13.3,
    marginVertical: 5,
  },
  postMessage: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
    color: colors.TEXT_WHITE,
    marginTop: 18,
  },
  commentMessage: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
    color: colors.TEXT_WHITE,
  },
  container: {
    flex: 1,
    backgroundColor: colors.BK_COLOR_VD,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10
  },
  settingsBox: {
    backgroundColor: '#eee',
    padding: 5,
    margin: 10,
  }
});

