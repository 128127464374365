import axios from '../configurations/request';
import { CommentDto } from "../entities/interfaces";
import { configService } from "./Config";

export class CommentService {
  async getComments(postId: string): Promise<Array<CommentDto>> {
    console.log('getting comments:', postId)
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}comments/post/${postId}`
    });
    return req.data;
  }

  // comments/botReply/:commentId
  async saveEditedBotReply(commentId: string, newReply: string) {
    console.log('saving bot reply', commentId, newReply)
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'PATCH',
      url: `${baseUrl}comments/botReply/${commentId}`,
      data: {
        newReply
      }
    });
    return req.data;
  }

  // Triggers the reply post, edited || bot
  async postBotReply(commentId: string) {
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'GET',
      url: `${baseUrl}comments/botReply/triggerpost/${commentId}`
    });
    return req.data;
  }

  async resolveComment(commentId: string, resolve: boolean) {
    const baseUrl = configService.apiRoot;
    const req = await axios({
      method: 'PATCH',
      url: `${baseUrl}comments/resolve/${commentId}`,
      data: {
        resolve
      }
    });
    return req.data;
  }
}

export const commentService = new CommentService();