import React from 'react';
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';

// list of icons directory https://static.enapter.com/rn/icons/material-community.html

MIcon.loadFont();

type IconSizeProps = {
  iconSizes: keyof typeof IconSizes;
};

export interface IconProps {
  size: IconSizeProps['iconSizes'];
  name: string;
  color: string;
}

export const IconSizes = {
  small: 13,
  medium: 18,
  large: 23,
  extraLarge: 27,
};


export const MaterialIcon = ({name, size, color}: IconProps) => (
  <MIcon name={name} size={IconSizes[size]} color={color} />
);