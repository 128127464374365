
import { View, Text, Pressable, FlatList, Spacer, Center, VStack, Button, useToast } from "native-base";
import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { StyleSheet } from 'react-native';
import { PageService } from '../../services/Page';
import { userService } from "../../services/User";
import { colors } from "../../styles/theme.style";
import { MaterialIcon } from "../components/Icon";
import { Layout } from "../components/Layout";
import { MediaCard } from "../components/MediaCard";
import { AuthContext, AuthState } from "../context/AuthContext";

export const PagesScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const stackWidth = '268px';
  const stackHeight = '340px';
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [fbUserID, setFbUserId] = useState('');
  const [pages, setPages] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const load = () => {
    if (user?.userID) {
      setFbUserId(user?.userID);
    }
    if (loaded || !fbUserID) { // prevent loop, prevent undefined call
      return;
    };

    new PageService().getPages(fbUserID).then((res) => {

      console.log(res);
      const pages = [...res];
      //@ts-ignore
      setPages(pages);
      setLoaded(true);
    });
  }

  const getPages = async () => {
    try {
      const req = await userService.fbConnect(context.authState.user._id);
      console.log('post fbconnect', req.userID, req)
      await setFbUserId(req.userID);

      console.log('userid is set:', fbUserID)
      setLoaded(false);
      console.log('loaded false')

      load();
      console.log('post load call')
      // TODO reload page after connection.
    } catch (e) {
      if (e?.response?.data?.message === 'Forbidden: Wrong userId') {
        toast.show({ description: "This users facebook account does not match the logged in users account. Please change facebook accounts to continue" });
      }
    }

  }

  useEffect(() => {
    load();
    console.log('use effect hook');
  });

  return (
    <Layout>
      <View style={styles.container}>
        <Text style={styles.h4}>Connection hub - Facebook Pages {fbUserID}</Text>

        {!!fbUserID && pages.length ?
          <>
            <FlatList numColumns={3} data={pages} renderItem={({ item }) =>
              <Center style={styles.cards}>
                <MediaCard title={item.name} page={item} />
              </Center>
            } />
          </>
          :
          <View margin={10}>
          <VStack w={stackWidth} h={stackHeight} style={styles.emptyCard} backgroundColor={colors.BK_COLOR_VD}>
            <Center h={'full'}>
              <Text style={styles.p} alignSelf="center">Connect to Facebook to manage pages</Text>
              
              <Button
              leftIcon={<MaterialIcon name="facebook" size="large" color="#fff" />}
              backgroundColor={colors.FB_BLUE}
              w="90%" onPress={() => getPages()}>
              Connect With Facebook
            </Button>
            </Center>
          </VStack>
          </View>
        }



      </View>
    </Layout>
  );
};

const borderRadius = 20;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.BK_COLOR_DK,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#eee',
    padding: 10,
    margin: 10
  },
  cards: {
    padding: 10,
  },
  h4: {
    fontWeight: '600',
    fontSize: 20,
    lineHeight: 28,
    color: colors.TEXT_WHITE,
    margin: 10,
  },
  p: {
    fontWeight: '300',
    fontSize: 12,
    lineHeight: 18,
    color: colors.TEXT_WHITE,
    margin: 10,
  },
  emptyCard: {
    borderRadius,
    border: `1px dashed ${colors.BRAND_ACCENT}`
  },
  emptyBtn: {
    paddingHorizontal: 20,
    borderRadius: 30,
    borderColor: colors.BRAND_ACCENT
  },
});
