
import { StyleSheet, View, Pressable } from 'react-native';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, Container, FormControl, Icon, Image, Input, Link, Spacer, Square, Stack, Text, useToast, WarningOutlineIcon } from 'native-base';
import { userService } from '../../services/User';



export const StartPasswordResetScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  
  const [email, setEmail] = useState('');

  const sendEmail = async () => {
    try {
      await userService.startPasswordReset(email);
      toast.show({description: "Check your email for a link to reset your password", duration: null})
      setEmail('');
    } catch(e) {
      toast.show({description: "Uh oh. Something went wrong! please call 864-310-0337 for support"})
    }
  }

  return (
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={170}>


          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Text fontSize='3xl' style={styles.heading}>
            Update Password
          </Text>
        </Square>

        <Square w={360}>

          <FormControl isRequired>
            <Stack mx="4">
              <FormControl.Label><Text style={styles.formLabel}>Email</Text></FormControl.Label>
              <Input type="email" w="full"
                color={colors.TEXT_WHITE}
                value={email} onChangeText={setEmail}
              />
              <FormControl.HelperText>
                Enter email to continue
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please enter a valid email
              </FormControl.ErrorMessage>


            </Stack>
          </FormControl>
          
          <Box w="full" p="16px">
            <Button w="full" onPress={() => sendEmail()}>Send Password Reset Link</Button>
          </Box>
        </Square>
        <Square w={360}>
          <Text color={colors.TEXT_WHITE}>
            Remembered your password? &nbsp;
            <Link color='primary.600' href='Login'>
              Sign In
            </Link>
          </Text>
        </Square>
      </Square>
    </Center>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 20,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  }
});