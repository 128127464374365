import { ProSidebarProvider } from 'react-pro-sidebar';
import { NavigationContainer } from '@react-navigation/native';
import { createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { PageScreen } from './modules/screens/Page';
import { PagesScreen } from './modules/screens/Pages';
import { PostScreen } from './modules/screens/Post';
import { LoginScreen } from './modules/screens/Login';
import { CommentScreen } from './modules/screens/Comment';
import { SandboxScreen } from './modules/screens/Sandbox';
import { extendTheme, NativeBaseProvider, View } from 'native-base';
import { useContext } from 'react';
import { AuthProvider, AuthContext, AuthState } from './modules/context/AuthContext';
import { RegisterScreen } from './modules/screens/Register';
import { SetupScreen } from './modules/screens/Setup';
import { BlogHelperScreen } from './modules/screens/BlogHelper';
import { ContactUsScreen } from './modules/screens/ContactUs';
import { CheckoutResultScreen } from './modules/screens/CheckoutResult';
import ReactGa from 'react-ga4';
import { ArticleDetailsHelperScreen } from './modules/screens/ArticleDetailsHelper';
import { HomeScreen } from './modules/screens/Home';
import { UpdatePasswordScreen } from './modules/screens/UpdatePassword';
import { StartPasswordResetScreen } from './modules/screens/StartPasswordReset';
import { ChatGPTTerminalScreen } from './modules/screens/ChatGptTerminal';
import { CartScreen } from './modules/screens/Cart';
import { IdeaExchangeScreen } from './modules/screens/IdeaExchange';

// Google Analytics 4 setup
const trackingId = 'G-2GZGRLJRWT';
ReactGa.initialize([{ trackingId }]);

const linking = {
  prefixes: [],
  config: {
    screens: {
      BlogAi: 'blog_ai',
      Cart: 'cart',
      Pages: 'pages/facebook',
      Page: 'page',
      Post: 'post',
      Comment: 'comment',
      Sandbox: 'sandbox',
      Dashboard: 'home',
      Register: 'sign_up',
      Setup: 'config',
      UpdatePassword: 'update_password',
      StartUpdatePassword: 'start_reset',
      Login: '*',
      ContactUs: 'contact_us',
      CheckoutResult: 'checkout_result',
      ArticleTools: 'article_tools',
      IdeaExchange: 'idea_exchange',
      ChatGPTTerminal: 'gpt_term',
    },
  }
};

const cheetahTheme = {
  colors: {
    "primary": {
      "50": "#ffccae",
      "100": "#ffb387",
      "200": "#fa9b64",
      "300": "#f38443",
      "400": "#f1681b",
      "500": "#f1681b",
      "600": "#f1681b",
      "700": "#aa511e",
      "800": "#904920",
      "900": "#794120"
    }
  }
};

const theme = extendTheme(cheetahTheme);

/** comment out, not using fb integration atm */
/*const loadFbSDk = (callback: Function) => {
  const existingScript = document.getElementById('fbsdk');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.id = 'fbsdk';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) { callback() };
    };
  }
  if (existingScript && callback) { callback() };
};

const fbAsyncInit = () => {
  window.FB.init({
    appId: '1781425105574269',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v15.0'
  });
};
*/

const Stack = createNativeStackNavigator();

function Stacks() {
  const context = useContext(AuthContext);
  const navigationRef = createNavigationContainerRef()

  const subscribed = (authState: AuthState) => {
    console.log('sub level: ', authState.user?.subscriptionLevel, authState)
    if (!authState.user) {
      return true;
    }
    
    // uncomment the following to enable CC check
    return true; //authState.user?.subscriptionLevel
  }

  return (

    // TODO: https://github.com/react-navigation/react-navigation.github.io/issues/97#issuecomment-748498698
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>

        {!context.authState.authenticated ?
          (<>
            <Stack.Screen name="Login" options={{ title: "Sign in" }} component={LoginScreen} />
            <Stack.Screen name="Register" options={{ title: "Sign up" }} component={RegisterScreen} />
            <Stack.Screen name="CheckoutResult" options={{ title: "Checkout Result" }} component={CheckoutResultScreen} />
            <Stack.Screen name="UpdatePassword" options={{ title: "Update Password" }} component={UpdatePasswordScreen} />
            <Stack.Screen name="StartUpdatePassword" options={{ title: "Send Password Link" }} component={StartPasswordResetScreen} />
          </>) :
          (<>
            {subscribed(context.authState) ?
              <>
                <Stack.Screen name="BlogAi" options={{ title: "Blog AI" }} component={BlogHelperScreen} />
                <Stack.Screen name="Dashboard" options={{ title: "Home" }} component={HomeScreen} />
                <Stack.Screen name="Page" options={{ title: "Page - Posts" }} component={PageScreen} />
                <Stack.Screen name="Pages" options={{ title: "Pages" }} component={PagesScreen} />
                <Stack.Screen name="Post" options={{ title: "Post" }} component={PostScreen} />
                <Stack.Screen name="Comment" options={{ title: "Comment" }} component={CommentScreen} />
                <Stack.Screen name="ArticleTools" options={{ title: "Article Tools" }} component={ArticleDetailsHelperScreen} />
                <Stack.Screen name="Setup" options={{ title: "Setup" }} component={SetupScreen} />
                <Stack.Screen name="Register" options={{ title: "Sign up" }} component={RegisterScreen} />
                <Stack.Screen name="ContactUs" options={{ title: "Contact Us" }} component={ContactUsScreen} />
                <Stack.Screen name="CheckoutResult" options={{ title: "Checkout Result" }} component={CheckoutResultScreen} />
                <Stack.Screen name="ChatGPTTerminal" options={{ title: "Chat GPT Terminal" }} component={ChatGPTTerminalScreen} />
                <Stack.Screen name="IdeaExchange" options={{ title: "Idea Exchange" }} component={IdeaExchangeScreen} />
                <Stack.Screen name="UpdatePassword" options={{ title: "Update Password" }} component={UpdatePasswordScreen} />
                <Stack.Screen name="StartUpdatePassword" options={{ title: "Send Password Link" }} component={StartPasswordResetScreen} />
                <Stack.Screen name="Cart" options={{ title: "Cart" }} component={CartScreen} />
              </>
              :
              <>
                <Stack.Screen name="Cart" options={{ title: "Cart" }} component={CartScreen} />
              </>
            }
          </>)}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default function App() {
  //loadFbSDk(fbAsyncInit);

  return (
    <NativeBaseProvider theme={theme}>
      <ProSidebarProvider>


        <AuthProvider>
          <Stacks></Stacks>

        </AuthProvider>

      </ProSidebarProvider>
    </NativeBaseProvider>
  );
}
