import "@expo/match-media";

import { useMediaQuery } from 'react-responsive';

export const useResponsiveSize = () => {
  const isDeviceWidth765_above = useMediaQuery({ query: '(min-device-width: 765x)'});
  const isDeviceWidth1124px_below = useMediaQuery({ query: '(max-device-width: 1124px)' });
  const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 764px)' });

  const isDeviceWidth490_below = useMediaQuery({ query: '(max-device-width: 490px)' });

  return {
    isTabletOrMobileDevice,
    isDeviceWidth765_above,
    isDeviceWidth490_below,
    isDeviceWidth1124px_below
  };
};
