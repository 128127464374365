import {
  View,
  Text,
  FlatList,
  VStack,
  Button,
  useToast,
  Input,
  ScrollView,
  HStack,
  Spinner,
  Spacer,
  Box,
  Select,
} from "native-base";
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  ReactElement,
} from "react";
import {
  ListRenderItem,
  StyleSheet,
} from "react-native";
import { colors, defaultStyles } from "../../styles/theme.style";
import { Layout } from "../components/Layout";
import { AuthContext, AuthState } from "../context/AuthContext";
import { ConversationObj, ConversationPrompt, Message, cheetahService } from "../../services/Chetah";
import { useResponsiveSize } from "../helpers/ResponsiveSize";
import { ConversationList } from "../components/ConversationList";
import { prompts } from "../etc/IdeaExchangePrompts";


export const IdeaExchangeScreen: FunctionComponent<any> = ({
  route,
  navigation,
}) => {
  const toast = useToast();
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [messages, setMessages] = useState<Message[]>([]);
  const [userPrompt, setUserPrompt] = useState("");
  const [settingsId, setSettingsId] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [loading, setLoading] = useState<Boolean>(false);
  const [conversations, setConversations] = useState<ConversationObj[]>([]);
  const [conversationPrompts, setConversationPrompts] = useState<ConversationPrompt[]>();
  const [selectedConversationPromptId, setSelectedConversationPromptId] = useState<string>('1');
  const [selectItems, setSelectItems] = useState<any>([]);

  const loadConversations = () => {
    if (!settingsId) {
      return;
    }

    (async () => {
      const _conversations = await cheetahService.getConversations(settingsId);
      setConversations(_conversations);
    })();
  }

  const loadConversationId = () => {
    setConversationId(route?.params?.conversationId);
  };

  const loadConversation = () => {
    console.log('load conv', settingsId, conversationId);
    if (!settingsId || !conversationId) {
      return;
    }

    (async() => {
      const _conversation = await cheetahService.getConversation(settingsId, conversationId);
      setMessages(_conversation.messages);
    })();
  };

  const loadSettingsId = () => {
    if (user?._id) {
      setSettingsId(user._id);
    }
  };

  const newConversation = async () => {
    navigation.navigate('IdeaExchange');
    setConversationId('');
    const selectedPrompt = conversationPrompts?.find(prompt => prompt.id === selectedConversationPromptId);
    setMessages(selectedPrompt?.messages || []);
    /*
    // Only create conversation after posting
    const conversationObj = await cheetahService.createConversation(
      settingsId,
      prompts.ideaWorkshop.prompt
    );
    navigation.setParams({
      conversationId: conversationObj._id,
    });*/

    loadConversations();
  }

  const submitPrompt = async () => {
    setLoading(true);
    const newConversation = !conversationId;

    //
    let conversationObj;

    if (newConversation) {
      try {
        if (selectedConversationPromptId === '1') { // basic prompt
          conversationObj = await cheetahService.createConversation(
            settingsId,
            userPrompt
          );
        } else {
          conversationObj = await cheetahService.createConversationWithPrompt(
            settingsId,
            selectedConversationPromptId,
            userPrompt
          );
        }
      } catch (e) {
        setLoading(false);
        toast.show({ description: 'Error getting results.' });
        return;
      }


      navigation.setParams({
        conversationId: conversationObj._id,
      });

      loadConversations();
    } else {
      conversationObj = await cheetahService.addToConversation(
        settingsId,
        conversationId,
        userPrompt
      );
    }

    setUserPrompt('');
    setMessages(conversationObj.messages);
    setLoading(false);
  };

  const onConversationPromptIdChange = (val: string) => {
    setSelectedConversationPromptId(val);
    if (!conversationId) {
      const selectedPrompt = conversationPrompts?.find(prompt => prompt.id === val);
      setMessages(selectedPrompt?.messages || []);
    }
  }

  const renderConversation: ListRenderItem<any> = ({
    item,
    index,
  }: {
    item: Message;
    index: number;
  }) => (
    
    <VStack width={"full"}>
      {/** todo replace with an icon of cheetah / person */}
      <HStack style={index % 2 !== 0 ? styles.oddBox : styles.evenBox}>
        <Text style={styles.p}>{item.role === 'user' ? 'You:' : 'AI Cheetah:'}</Text>
        <Text style={styles.p}>{item?.content}</Text>
      </HStack>
    </VStack>
  );

  async function loadConversationPrompts () {
    const conversationPrompts = await cheetahService.fetchConversationPrompts();
    setConversationPrompts(conversationPrompts);
    const items = conversationPrompts?.map((prompt: ConversationPrompt) => {
      return <Select.Item key={prompt.id} value={prompt.id} label={prompt.label} />
    });
    items.unshift(<Select.Item key='1' value='1' label='Basic Chat' />)
    setSelectItems(items);
  };

  useEffect(loadConversationId, [route?.params?.conversationId]);
  useEffect(loadConversations, [settingsId]);
  useEffect(() => {loadConversationPrompts()}, []);
  useEffect(loadConversation, [conversationId, settingsId])
  useEffect(loadSettingsId, [user]);

  const { isDeviceWidth490_below } = useResponsiveSize();

  return (
    <Layout>
      <View style={styles.container}>
        <HStack flex="1" mx={'0'} padding={0}>
          <HStack width={"1/6"}>
            <ScrollView h="full" style={styles.leftColumn}>
              <Text style={styles.labelText}>History:</Text>
              <ConversationList conversations={conversations}/>
            </ScrollView>
          </HStack>
          <VStack flex={'1'}>
          <HStack mx="4">
            <Text style={styles.h4}>Chat GPT Terminal</Text>
          </HStack>

          <ScrollView flex={'1'} padding={5}>

            <HStack m={5} maxW="600">
              <Select
                style={styles.inputGeneral}
                selectedValue={selectedConversationPromptId}
                minWidth="200"
                accessibilityLabel="Choose an AI Helper"
                placeholder="Choose a AI helper"
                color={"white"}
                mt={1}
                onValueChange={(val) =>
                  onConversationPromptIdChange(val)
                }
              >
                {selectItems}
              </Select>
              {conversationId ? 
                <View><Spacer /><Button size={'sm'} onPress={newConversation}>+ New</Button></View> 
                : ''}
            </HStack>


            <VStack >
              <FlatList flex={"1"} height={"full"} data={messages} renderItem={renderConversation} />
              {loading && <Spinner></Spinner>}
              <VStack style={styles.labelStack} >
                <Text style={styles.labelText} marginTop={5}>Enter your message:</Text>
                <Input style={styles.inputGeneral} value={userPrompt} onChangeText={setUserPrompt} onSubmitEditing={submitPrompt} />
                <View width={"1/6"} marginTop={4}><Button onPress={submitPrompt}>Submit</Button></View>
              </VStack>
            </VStack>
          </ScrollView>
          </VStack>
        </HStack>
      </View>
    </Layout>
  );
};

const borderRadius = 20;

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles, {
    iframe: {
      border: "none",
      backgroundColor: "white",
    },
    stepBox: {
      backgroundColor: colors.BK_COLOR_VVLT,
    },
    oddBox: {
      backgroundColor: colors.BK_COLOR_VVLT,
    },
    evenBox: {},
    labelStack: {},
    leftColumn: {
    },
  })
);
