import { Component } from "react";
import { ListRenderItem, StyleSheet } from "react-native";
import { colors, defaultStyles } from "../../styles/theme.style";
import { ConversationObj, Message } from "../../services/Chetah";
import { Pressable, FlatList, HStack, Text, VStack, View } from "native-base";
import { useNavigation } from "@react-navigation/native";
let navigation: any;
let goTo: Function;

const renderList: ListRenderItem<any> = ({ item, index }: { item: ConversationObj, index: number }) => (

  <VStack width={'full'}>
    {/** todo replace with an icon of cheetah / person */}
    <HStack>
      <Pressable alignContent={'left'} style={styles.stackedButton} width={'full'}
        onPress={() => {
          goTo(item._id)
        }}>
        <Text width={'full'} alignSelf={'left'} style={styles.stackedButtonText} numberOfLines={1}>{item?.title || 'no title..'}</Text>
      </Pressable>
    </HStack>
  </VStack>
);


export function ConversationList(data: { conversations: ConversationObj[] }) {
  navigation = useNavigation();
  goTo = (_id: string) => {
    navigation.setParams({
      conversationId: _id,
    });
  }

  const { conversations } = data;
  return (
    <View>
      <FlatList data={conversations} renderItem={renderList}>
      </FlatList>
    </View>
  )

}

const styles = StyleSheet.create(
  {
    text: {
      color: 'red'
    },
    stackedButton: {
      borderRadius: 0,
      margin: 0,
      padding: 5,
      backgroundColor: colors.BK_COLOR_VVLT,
      borderBottomColor: colors.BK_COLOR_VD,
      borderStyle: 'solid',
      borderBottomWidth: 1
    },
    stackedButtonText: {
      color: colors.TEXT_WHITE,
    }
  }

);