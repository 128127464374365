import axios from '../../configurations/request';
import { configService } from '../../services/Config';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Pressable } from 'react-native';
import { FunctionComponent, useContext, useState } from 'react';
import { Layout } from '../components/Layout';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, Container, FormControl, HStack, Icon, Image, Input, Link, Spacer, Spinner, Square, Stack, Text, useToast, WarningOutlineIcon } from 'native-base';
import { stripeService } from '../../services/Stripe';




export const CheckoutResultScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const [working, setWorking] = useState(false);
  const resumeCheckout = async () => {
    setWorking(true);
    const res = await stripeService.createCheckoutSession();
    
    const {url} = res;
    window.location = url;
    setWorking(false);
  }

  // Screen used for checkout cancel only
  return (
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={300}>
          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Text fontSize='xl' style={styles.heading}>
            Looks like you don't have an active subscription or trial.
          </Text>
          <Text fontSize='md' style={styles.heading}>
            Please use our Stripe checkout to continue. Enjoy a 30 day free trial. Cancellation is easy as clicking "Account" & "Cancel" in the app.
          </Text>
        </Square>

        <Square w={360}>

          
        </Square>
        <Square w={360}>
          <Box w="full" p="16px">
          <Button onPress={() => {resumeCheckout()}} style={styles.heading}>
            <HStack>
            {working && <Spinner accessibilityLabel="Loading" color="cyan.500" />}
            <Text marginLeft={5}>Stripe Checkout</Text>
            </HStack>
          </Button>
          </Box>
          
        </Square>
      </Square>
    </Center>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 20,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  }
});